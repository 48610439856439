import gql from 'graphql-tag';
import { userFragment } from './UserQueries';

export const SETUP_QUERY = gql`
  {
    currentUser {
      ...userValues
    }
  }
  ${userFragment}
`;

export const BANNER_QUERY = gql`
  query shopBanner($region: String!) {
    shopBanner(region: $region) {
      region
      message
      active
    }
  }
`;
