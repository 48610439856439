import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Loader, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER } from '../queries/UserQueries';
import ShopHeader from './ShopHeader';
import { getCycle } from '../helpers/deliverycycle';
import ShopContent from './ShopContent';
import { GET_ORDERS } from '../queries/OrderQueries';

const Shop = () => {
  const userQuery = useQuery(GET_USER);
  const ordersQuery = useQuery(GET_ORDERS, {
    variables: { startWeek: moment().isoWeek() - 1, shopSelection: 'Business' },
  });
  const [additionalOrder, setAdditionalOrder] = useState(false);

  let currentUser = {};
  if (!userQuery.loading) {
    currentUser = userQuery.data.currentUser;
  }

  let orders = [];
  if (!ordersQuery.loading) {
    orders = ordersQuery.data.getOrders;
  }

  if (userQuery.loading) return <Loader active inline="centered" />;

  const { deliveryWeek, openingMoment, closingMoment, nextOpeningMoment, nextClosingMoment } =
    getCycle(currentUser.regions[0]);
  const onCycle = false;
  const hasOrderThisCycle =
    orders[0] &&
    moment(orders[0].orderDate, 'x').isBetween(openingMoment, closingMoment, undefined, '[]');

  const Content = () => {
    if ((onCycle && !hasOrderThisCycle) || additionalOrder) return <ShopContent />;
    if (onCycle && hasOrderThisCycle) {
      return (
        <div className="oncycle already-ordered">
          <div className="cycle-message-content">
            <div className="textwrapper">
              <h3>
                Vielen Dank für Ihre Bestellung <strong>{currentUser.recentOrderNumber}</strong>
                <br />
              </h3>
              <p>
                Änderungswünsche können Sie bis zum Ablauf der
                <br />
                Bestellfrist eigenständig im Bereich{' '}
                <Link to="/order-history">Meine Bestellungen</Link>
                <br />
                vornehmen.
              </p>
              <Link to="/order-history">
                <Button primary>Meine Bestellung anzeigen</Button>
              </Link>
              <Button secondary onClick={() => setAdditionalOrder(true)}>
                Weitere Bestellung tätigen
              </Button>
            </div>
          </div>
        </div>
      );
    } else if (!onCycle && !hasOrderThisCycle) {
      return (
        <>
          <div className="offcycle">
            <div className="cycle-message-content">
              <div className="textwrapper">
                <p>
                  Liebe Partner,
                  <br />
                  <br />
                  <strong>
                    Die Bestellzeit für Lieferungen in der KW
                    {deliveryWeek < 10 ? `0${deliveryWeek}` : deliveryWeek} ist abgelaufen.
                  </strong>
                  <br />
                </p>
                <h3>Nächste Bestellphase:</h3>
                <p>
                  {nextOpeningMoment.format('dddd')}, den {nextOpeningMoment.format('DD.MM.YYYY')}{' '}
                  <br />
                  bis {nextClosingMoment.format('dddd')}, den{' '}
                  {nextClosingMoment.format('DD.MM.YYYY')}
                </p>
                <br />
                <p>
                  Tragen Sie sich in unseren{' '}
                  <a
                    href="https://querfeld.bio/aussortiertes-obst-gemuese-kaufen#angebotsverteiler"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    eMail-Verteiler
                  </a>{' '}
                  ein, um die nächste
                  <br />
                  Bestellphase nicht mehr zu verpassen.
                </p>
              </div>
            </div>
          </div>
          <div className="footer">
            <span>
              Benötigen Sie Hilfe oder haben Sie Fragen zu Ihrer Bestellung?
              <br />
              Besuchen Sie unsere <Link to="/contact">Hilfeseite</Link> oder schreiben Sie uns an{' '}
              <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a>
            </span>
          </div>
        </>
      );
    } else if (!onCycle && hasOrderThisCycle) {
      return (
        <>
          <div className="offcycle">
            <div className="cycle-message-content">
              <div className="textwrapper">
                <h3>Nächste Bestellphase:</h3>
                <p>
                  {nextOpeningMoment.format('dddd')}, den {nextOpeningMoment.format('DD.MM.YYYY')}{' '}
                  <br />
                  bis {nextClosingMoment.format('dddd')}, den{' '}
                  {nextClosingMoment.format('DD.MM.YYYY')}
                </p>
                <p>
                  <strong>Vielen Dank für Ihre Bestellung.</strong>
                  <br />
                  <br />
                  Änderungswünsche können Sie bis zum Ablauf der
                  <br />
                  Bestellfrist eigenständig im Bereich{' '}
                  <Link to="/order-history">Meine Bestellungen</Link>
                  <br />
                  vornehmen.
                </p>
                <Button primary href="/order-history" rel="noopener noreferrer">
                  Meine Bestellungen anzeigen
                </Button>
              </div>
            </div>
          </div>
          <div className="footer">
            <span>
              Benötigen Sie Hilfe oder haben Sie Fragen zu Ihrer Bestellung?
              <br />
              Besuchen Sie unsere <Link to="/contact">Hilfeseite</Link> oder schreiben Sie uns an{' '}
              <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a>
            </span>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Shop - Querfeld</title>
      </Helmet>
      <div className="shop">
        <ShopHeader />
        <Content />
      </div>
    </>
  );
};

export default Shop;
