import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { Header, Icon, Loader, Message } from 'semantic-ui-react';
import { FOOD_WASTE_REPORTS, GET_USER } from '../queries/UserQueries';
import { getCycle } from '../helpers/deliverycycle';
import foodWasteImage from '../assets/foodsaver_widget.png';
import { BANNER_QUERY } from '../queries/SetupQueries';

const ShopHeader = () => {
  const userQuery = useQuery(GET_USER);
  const foodWasteQuery = useQuery(FOOD_WASTE_REPORTS);
  const [fetchShopBanner, shopBannerQuery] = useLazyQuery(BANNER_QUERY);

  if (userQuery.loading || foodWasteQuery.loading) return <Loader active inline="centered" />;

  let regions = [];
  let isIndirectSalesChannel = false;
  if (!userQuery.loading) {
    regions = userQuery.data.currentUser.regions;
    isIndirectSalesChannel = ['NET3', 'NET7', 'NET20'].includes(
      userQuery.data.currentUser.salesChannel
    );
    if (!shopBannerQuery.called) {
      fetchShopBanner({ variables: { region: regions[0] } });
    }
  }
  const { onCycle, deliveryWeek, nextOpeningMoment, closingMoment } = getCycle(regions[0]);

  const hasReports = foodWasteQuery.data.foodWasteReports.length > 0;
  let latestDocument;
  if (hasReports) {
    [latestDocument] = foodWasteQuery.data.foodWasteReports.sort((a, b) =>
      parseInt(a.lastModifiedDate, 10) > parseInt(b.lastModifiedDate, 10) ? -1 : 1
    );
  }

  const bannerMessage =
    shopBannerQuery.data &&
    shopBannerQuery.data.shopBanner &&
    shopBannerQuery.data.shopBanner.active &&
    shopBannerQuery.data.shopBanner.message &&
    shopBannerQuery.data.shopBanner.message !== ''
      ? shopBannerQuery.data.shopBanner.message
      : null;

  return (
    <div className="shop-table-header">
      <div className={`shop-header ${onCycle ? 'oncycle-header' : 'offcycle-header'}`}>
        <div className="headline-container">
          <Header as="h1" className="header--is-handwritten">
            {onCycle ? 'Bestellphase' : 'Lieferphase'}:
          </Header>
          <Header as="h2">
            Lieferwoche KW{deliveryWeek < 10 ? `0${deliveryWeek}` : deliveryWeek}
          </Header>
          <Header as="h3">Unser Angebot für {regions[0]}</Header>
          {!onCycle && (
            <Header as="h4">
              Bestellungen für KW{deliveryWeek < 10 ? `0${deliveryWeek}` : deliveryWeek} werden
              bearbeitet.
            </Header>
          )}
        </div>
        <div className="eyecatcher">
          {onCycle ? (
            <>
              Bestellschluss
              <br />
              <strong>
                {closingMoment.format('DD.MM.')} - {closingMoment.hour()} Uhr
              </strong>
            </>
          ) : (
            <>
              Nächste Bestellphase
              <br />
              <strong>
                {nextOpeningMoment.format('DD.MM.')}
                {nextOpeningMoment.hour() > 4 && ` - ${nextOpeningMoment.hour()} Uhr`}
              </strong>
            </>
          )}
        </div>
        {/* <div className="eyecatcher notice">
          <strong>Achtung:</strong><br />
          Aufgrund der Feiertage weichen die Liefertage von den im Shop
          angezeigten Daten ab, bitte die Angebotsemail beachten.
        </div> */}
      </div>
      {bannerMessage && (
        <Message
          visible
          warning
          icon="warning sign"
          content={bannerMessage}
          style={{ marginLeft: '2em', maxWidth: '500px' }}
        />
      )}
      {hasReports && (
        <div className="food-waste-container">
          <img className="food-waste-logo" alt="Food Save reports" src={foodWasteImage} />
          <div className="food-waste-links">
            <a href={latestDocument.url} target="_blank" rel="noopener noreferrer">
              <Icon name="download" />
              Download Food-Save-Report
            </a>
            <a
              href={
                isIndirectSalesChannel
                  ? 'https://drive.google.com/drive/folders/10KkP9pWKE7KMMFwROAyKy6pQznwITGi0'
                  : 'https://drive.google.com/drive/folders/1XBXIdmWS6PWyEt09kieHlKVkURd-yBnu'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="share square" />
              Weiteres Marketingmaterial öffnen
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopHeader;
